<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<footer class="relative px-4 py-8 mx-auto max-w-screen-2xl w-full grid grid-cols-3 gap-y-16 font-redonda italic">
		<div class="lg:max-w-[527px] col-span-3 lg:col-span-2 text-center lg:text-left">
			<p class="mt-2 text-[25px] font-normal leading-tight mb-8">Impulsione resultados com gamificação! 🚀</p>

			<p class="mt-2 text-[25px] font-normal leading-tight">
				Transforme desafios em motivação e eleve a performance em qualquer área com um sistema dinâmico e envolvente.
			</p>
		</div>

		<div class="items-center justify-center flex flex-col gap-16 col-span-3 lg:col-span-1">
			<NuxtLink
				:href="whatsappLink"
				target="_blank"
				class="z-50 px-20 py-3 text-white text-center text-[36px] font-bold rounded-full bg-[#FF2C3C] border-white border-[4px]"
			>
				Mais Sobre nós
			</NuxtLink>

			<div class="items-center justify-center flex flex-row gap-8">
				<NuxtLink external target="_blank" href="https://www.instagram.com/gamefiqueapp/">
					<Icon name="lucide:instagram" class="h-16 w-16 grayscale hover:grayscale-0" />
				</NuxtLink>

				<NuxtLink external target="_blank" href="https://www.instagram.com/gamefiqueapp/">
					<Icon name="lucide:linkedin" class="h-16 w-16 grayscale hover:grayscale-0" />
				</NuxtLink>

				<NuxtLink external target="_blank" href="https://www.instagram.com/gamefiqueapp/">
					<Icon name="ic:sharp-whatsapp" class="h-16 w-16 grayscale hover:grayscale-0" />
				</NuxtLink>
			</div>
		</div>

		<div class="col-span-3 font-normal text-center text-[20px]">
			<span>Todos os direitos reservados. {{ new Date().getFullYear() }} © GameFique</span>
		</div>

		<svg
			class="absolute bottom-0 left-0 translate-x-4 -translate-y-8"
			width="106"
			height="106"
			viewBox="0 0 106 106"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_28_10)">
				<path
					d="M105.93 52.9201C105.96 63.3701 105.3 73.7401 102.85 83.9401C102.65 84.7601 102.39 85.5701 102.2 86.3901C100.18 95.1801 94.56 100.17 85.93 102.31C78.73 104.09 71.45 105.13 64.08 105.58C51.96 106.32 39.87 105.98 27.87 103.98C23.58 103.27 19.39 102.22 15.32 100.64C10.39 98.7201 7.09 95.3101 5.19 90.4101C3 84.7401 1.86 78.8301 1.18 72.8301C-0.55 57.5301 -0.46 42.2601 2.1 27.0401C2.71 23.4201 3.67 19.9001 4.85 16.4201C6.83 10.6201 10.71 6.85012 16.47 4.83012C22.42 2.74012 28.57 1.71012 34.78 0.970121C41.05 0.230121 47.36 -0.069879 53.66 0.010121C64.31 0.160121 74.89 0.950121 85.33 3.45012C94.87 5.73012 100.41 11.4001 102.63 20.9101C105.09 31.4701 105.94 42.1401 105.93 52.9201ZM54.74 98.3001C59.34 98.4201 65.74 97.9801 72.12 97.1901C77.16 96.5701 82.13 95.6201 86.95 93.9401C90.37 92.7501 92.73 90.6601 93.92 87.1601C94.98 84.0501 95.83 80.9101 96.4 77.6901C98.51 65.6401 98.85 53.4901 98.01 41.3201C97.53 34.3501 96.52 27.4601 94.62 20.6901C93.18 15.5801 90.06 12.6901 85.05 11.3101C78.44 9.49012 71.72 8.50012 64.91 8.02012C54.77 7.30012 44.64 7.46012 34.54 8.66012C28.99 9.32012 23.51 10.2801 18.26 12.3001C15.53 13.3501 13.6 15.1001 12.47 17.8301C11.04 21.3001 10.16 24.9001 9.55 28.5701C7.15 42.8301 7.07 57.1601 8.68 71.5001C9.32 77.2201 10.33 82.8801 12.55 88.2401C13.5 90.5301 15.01 92.2201 17.33 93.2101C19.26 94.0301 21.22 94.7201 23.24 95.2201C32.99 97.6301 42.92 98.2701 54.75 98.2801L54.74 98.3001Z"
					fill="white"
				/>
				<path
					d="M35.44 64.84C31.22 64.84 26.99 64.84 22.77 64.84C20.04 64.84 19.37 63.75 20.66 61.41C23.26 56.67 25.94 51.97 28.48 47.2C29.51 45.27 30.94 44.43 33.11 44.53C35.25 44.63 37.39 44.49 39.53 44.57C40.7 44.61 41.4 44.18 41.94 43.16C43.81 39.69 45.79 36.28 47.63 32.8C48.47 31.21 49.64 30.53 51.42 30.54C58.86 30.58 66.29 30.53 73.73 30.58C75.08 30.58 75.98 30.22 76.5 28.92C77.29 26.94 78.77 26.09 80.9 26.29C81.79 26.37 82.7 26.27 83.6 26.32C85.76 26.43 86.51 27.72 85.47 29.63C83.34 33.54 81.17 37.42 79.01 41.31C71.07 55.64 63.12 69.98 55.19 84.32C53.62 87.17 49.62 88.57 46.57 87.29C45.37 86.79 45.09 85.6 45.88 84.16C48.13 80.07 50.42 75.99 52.68 71.9C56.25 65.45 59.82 58.99 63.38 52.53C64.25 50.94 64.12 50.7 62.34 50.7C55.64 50.68 48.93 50.7 42.23 50.7C41.89 50.7 41.55 50.72 41.22 50.77C40.88 50.82 40.53 50.96 40.5 51.36C40.47 51.76 40.81 51.94 41.13 52.05C41.98 52.34 42.84 52.61 43.7 52.87C46.51 53.71 49.33 54.5 52.12 55.39C54.44 56.13 55 57.52 53.85 59.67C50.65 65.66 51.45 64.81 45.4 64.86C42.08 64.89 38.75 64.86 35.43 64.86L35.44 64.84Z"
					fill="white"
				/>
				<path
					d="M37.25 27.54C37.25 28.06 36.96 28.39 36.53 28.59C33.66 29.93 31.57 32.02 30.16 34.85C29.61 35.96 28.72 35.95 28.15 34.85C26.72 32.03 24.62 29.96 21.78 28.57C20.73 28.06 20.71 27.18 21.76 26.68C24.68 25.28 26.78 23.15 28.2 20.25C28.7 19.23 29.58 19.21 30.1 20.27C31.49 23.11 33.53 25.26 36.43 26.57C36.85 26.76 37.21 27.03 37.26 27.55L37.25 27.54Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_28_10">
					<rect width="105.93" height="105.94" fill="white" />
				</clipPath>
			</defs>
		</svg>
	</footer>
</template>
